<template>
    <div class="user-index">
        <div class="main-h1">Создание дела</div>
        <form class="card" v-on:submit.prevent>
            <div class="card-title">Новый иск</div>
            <div class="card-body">
                <div class="card-body-title">Тип</div>
                <div class="row">
                    <div class="col-6">
                        <label class="form-label">
                            <div class="t">Тип иска *</div>
                            <select v-model="claim.type_id">
                                <option v-for="option in types" :value="option.id">{{ option.title }}</option>
                            </select>
                        </label>
                    </div>
                    <div class="col-6">
                        <label class="form-label">
                            <div class="t">Город *</div>
                            <select v-model="claim.city_id">
                                <option v-for="option in cities" :value="option.id">{{ option.title }}</option>
                            </select>
                        </label>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="card-body-title">Ответчик</div>
                <div class="row">
                    <div class="col-12">
                        <label class="form-label">
                            <div class="t">Организационно правовая форма ответчика *</div>
                            <select v-model="claim.defendant_type">
                                <option v-for="option in userOrgTypes" :value="option">{{ option }}</option>
                            </select>
                        </label>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="card-body-title">Суммы</div>
                <div class="row">
                    <div class="col-3">
                        <label class="form-label">
                            <div class="t">Сумма иска *</div>
                            <input v-model="claim.amount" :disabled="claim.type_id == 2"/>
                        </label>
                    </div>
                </div>
            </div>
            <button :disabled="!btnEnabled" class="btn green" @click="createClaim">Создать</button>
        </form>
    </div>
</template>

<script>
import userOrgTypes from "@/helpers/userOrgTypes";

export default {
    name: "create",
    data() {
        return {
            claim: {
                city_id: 0,
            },
            cities: [],
            types: [],
            userOrgTypes: userOrgTypes,
        }
    },
    methods: {
        createClaim() {
            this.$api.post(`claim_claimant`, this.claim).then(res => {
                if (res.data.success) {
                    this.claim = res.data.data;
                    this.$store.commit('showNotify', {
                        title: 'Сохранено',
                        content: 'Дело успешно создано.',
                        type: 'success'
                    });
                    this.$router.push(`/panel/claim/${res.data.data.claim_id}/edit_claimant`);
                } else {
                    this.$store.commit('showNotify', {title: 'Ошибка', content: res.data.error, type: 'danger'});
                }
            })
        },
        getCities() {
            this.$api.get(`city`).then(res => {
                if (res.data.success) {
                    this.cities = res.data.data
                } else {
                    this.$store.commit('showNotify', {title: 'Ошибка', content: res.data.error, type: 'danger'});
                }
            })
        },
        getTypes() {
            this.$api.get(`claim/types`).then(res => {
                if (res.data.success) {
                    this.types = res.data.data
                } else {
                    this.$store.commit('showNotify', {title: 'Ошибка', content: res.data.error, type: 'danger'});
                }
            })
        },
    },
    created() {
        this.getCities();
        this.getTypes();
    },
    computed: {
        btnEnabled: function () {
            return this.claim.defendant_type && this.claim.type_id && this.claim.city_id && (this.claim.amount || this.claim.type_id == 2);
        }
    },
    watch: {
        'claim.type_id': function () {
            if (this.claim.type_id == 2) { // Неимущественный спор
                this.claim.amount = 0;
            } else {
                this.claim.amount = null
            }
        }
    }
}
</script>

<style scoped>

</style>